<template>
  <div :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <v-card class="elevation-0">
      <v-stepper v-model="stepPosition" class="elevation-0 rounded-lg">
        <v-stepper-header
          class="elevation-0"
          :style="!darkmode && 'background-color: #F3F3F3'"
        >
          <v-stepper-step :complete="stepPosition > 1" step="1">
            <span>{{ $t("app.profile") }}</span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="stepPosition > 2" step="2">
            <span>{{ $t("homepage.purpose") }}</span>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="stepPosition > 3"
            class="d-flex flex-row"
            step="3"
          >
            {{ $t("homepage.vision") }}
            &
            {{ $t("homepage.mission") }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" class="stepper-content">
            <v-row>
              <v-col cols="12" sm="4" class="pl-6">
                <CropImage
                  :isEdit="loadingSave"
                  :value="bodyRequest.profile_path"
                  :croped-image="value => (bodyRequest.profile_path = value)"
                  :clearForm="clearFormImage"
                  id="profileImage"
                  cropBoxMovable
                  typeSize="landscape"
                  cropBoxResizable
                  @getImageName="value => (imageNameProfile = value)"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <quill-editor
                  v-model="bodyRequest.profile_desc"
                  :disabled="loadingSave"
                  :options="editorOption"
                  :class="{ 'white black--text': darkmode }"
                />
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" class="stepper-content">
            <quill-editor
              v-model="bodyRequest.purpose_desc"
              :disabled="loadingSave"
              :options="editorOption"
            />
          </v-stepper-content>
          <v-stepper-content step="3" class="stepper-content">
            <v-row>
              <v-col cols="12" sm="4">
                <CropImage
                  :isEdit="loadingSave"
                  :value="bodyRequest.visi_misi_path"
                  :croped-image="value => (bodyRequest.visi_misi_path = value)"
                  :clearForm="clearFormImage"
                  id="visionImage"
                  :aspectRatio="3 / 2"
                  :initialAspectRatio="3 / 2"
                  typeSize="landscape"
                  cropBoxResizable
                  @getImageName="value => (imageNameVision = value)"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <span class="font-weight-bold"
                  >{{ $t("homepage.vision") }} :</span
                >
                <quill-editor
                  v-model="bodyRequest.visi_desc"
                  :disabled="loadingSave"
                  :options="editorOption"
                  class="mb-5 mt-1"
                />
                <span class="font-weight-bold"
                  >{{ $t("homepage.mission") }} :</span
                >
                <quill-editor
                  v-model="bodyRequest.misi_desc"
                  :disabled="loadingSave"
                  :options="editorOption"
                  class="mt-1"
                />
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-divider />
      <v-card-actions class="pa-5">
        <v-btn
          :disabled="stepPosition === 1"
          :dark="!stepPosition === 1"
          class="mr-3"
          depressed
          outlined
          color="primary"
          @click="stepPosition -= 1"
        >
          <v-icon>mdi-arrow-left-bold</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
            {{ $t("app.previous") }}
          </span>
        </v-btn>
        <v-btn
          :disabled="stepPosition === 3"
          :dark="!stepPosition === 3"
          depressed
          outlined
          color="primary"
          @click="stepPosition += 1"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
            {{ $t("app.next") }}
          </span>
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn
            :loading="loadingSave"
            :disabled="stepPosition < 3"
            class="gradient-primary"
            depressed
            dark
            @click="save"
          >
            <v-icon>mdi-content-save</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
              {{ $t("app.save") }}
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

import { create, list } from "@/api/admin/settings/mainInfo";
import i18n from "@/i18n";
export default {
  metaInfo: {
    title: i18n.t("routes.main_info"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    quillEditor,
    CropImage: () => import("@/components/ImageCrop")
  },
  created() {
    window.katex = katex;
    this.getData();
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      imageNameVision: null,
      imageNameProfile: null,
      clearFormImage: false,
      bodyRequest: {
        profile_path: "",
        profile_desc: "",
        purpose_desc: "",
        visi_misi_path: "",
        visi_desc: "",
        misi_desc: ""
      },
      steps: 3,
      stepPosition: 1,
      loadingSave: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["formula"],
              ["link"]
            ]
          }
        }
      }
    };
  },
  methods: {
    async getData() {
      try {
        const response = await list();
        if (response.data.code) {
          const data = response.data.data.profile;
          if (data) {
            this.bodyRequest = data;
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    formValidate() {
      let result = true;
      if (!this.bodyRequest.profile_path) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.logo"));
      } else if (!this.bodyRequest.profile_desc) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.profile"));
      } else if (!this.bodyRequest.purpose_desc) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.purpose"));
      } else if (!this.bodyRequest.visi_misi_path) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.vision_img"));
      } else if (!this.bodyRequest.visi_desc) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.vision"));
      } else if (!this.bodyRequest.misi_desc) {
        result = false;
        this.snackBar(false, this.$i18n.t("imageSlider.msg.mission"));
      } else {
        result = true;
      }
      return result;
    },
    async save() {
      this.loadingSave = true;
      if (this.formValidate()) {
        try {
          let body = JSON.parse(JSON.stringify(this.bodyRequest));
          if (!this.imageNameProfile) body.profile_path = ""; // set empty string if image not changed
          if (!this.imageNameVision) body.visi_misi_path = ""; // set empty string if image not changed
          const response = await create(body);
          if (response.data.code) {
            this.clearFormImage = true;
            this.getData();
            this.snackBar(true, response.data.message);
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      }
      this.loadingSave = false;
    }
  }
};
</script>
